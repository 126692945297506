<template>
  <div class="clearfix footer">
    <div class="width-1200 pos-rel">
      <div class="clearfix">
        <img class="fxl img" src="../assets/img/logo_bottom.jpg">
        <div class="fxl">
          地址：北京市西城区南滨河路27号院贵都国际中心A座3层<br />
          电话：010-63453588<br />
          邮编：100055<br />
          E-mail：<a style="color:#fff;" href="mainto:zhongjia@zjic.com.cn">zhongjia@zjic.com.cn</a><br />
          Copyright 2021 中佳保险代理有限公司 版权所有&nbsp;&nbsp;&nbsp;&nbsp;<br/>备案号：京ICP备12001493号-1
        </div>
        <div class="fxr r-block">
          <ul>
            <li>
              <div class="img"><img src="../assets/img/01.png"></div>
              中佳保险
            </li>
            <li>
              <div class="img"><img src="../assets/img/03.jpg"></div>
              官方网站
            </li>
            <li>
              <div class="img"><img src="../assets/img/02.jpg"></div>
              壹佳保
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
};
</script>

<style lang="less" scoped>
.footer {
  display: block;
  padding: 30px 0px 10px 0px;
  background:url(../assets/img/bg_bottom.jpg) no-repeat center center;
  background-repeat: repeat-x;;
  color:#fff;
  .img{
    height:100px;
    margin-right:20px;
    img{
      height:100%;
    }
  }
  .r-block{
    ul{
      li{
        float:left;
        width:80px;
        margin:10px;
        text-align: center;
        font-size:16px;
        line-height: 30px;
        .img{
          width:80px;
          height:80px;
          background:#fff;
        }
      }
    }
  }
}

</style>