import Vue from "vue"
import VueRouter from "vue-router"

// 引入组件

import index from '../page/index'
import notFound from '../page/404'
import tongzgg_more from "../page/tongzgg_more"
import tongzgg_detail from "../page/tongzgg_detail"
import app_more from "../page/app_more"
import daib_more from "../page/daib_more"

// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

let routes = [
  {
    path: '/daib_more',
    component: daib_more
  },
  {
    path: '/app_more',
    component: app_more
  },
  {
    path: "/",
    component: index
  },
  {
    path: '/tongzgg_more',
    component: tongzgg_more
  },
  {
    path: '/404',
    component: notFound,
  },
  {
    path: '/tongzgg_detail',
    component: tongzgg_detail
  },
];
var router = new VueRouter({
  routes
})
export default router;