<template>
  <div class="clearfix">
    <header-pub></header-pub>
    <div class="clear h10"></div>
    <div class="clearfix width-1200">
      <el-page-header @back="goBack" content="应用系统"></el-page-header>
      <div class="clear h10"></div>
      <div class="clearfix main-con">
        <el-button class="fxr" type="primary" @click="showDlg">添加</el-button>
        <div class="clear"></div>
        <el-divider></el-divider>
        <div class="clear h10"></div>

        <el-row class="paddingNo marginNo">
          <el-col class="sub_title">
            <h3>快捷链接列表</h3>
          </el-col>
          <el-col class="text-center" v-for="(item, index) in shortcutList" :span="3" :key="index">
            <div class="block-list" @click="jump(item.url)">
              <img class="iconfont icon-text" :src="item.icon" />
              <p class="line-hei24">{{ item.title }}</p>
            </div>
            <template>
              <el-popconfirm title="确定删除吗?" @confirm="del(item.id)">
                <el-button slot="reference" type="danger">删除</el-button>
              </el-popconfirm>
            </template>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row class="paddingNo marginNo">
          <el-col class="sub_title">
            <h3>应用列表</h3>
          </el-col>
          <el-col class="text-center" v-for="(item, index) in appList" :span="3" :key="index">
            <div class="block-list" @click="jump(item.url)">
              <img class="iconfont icon-text" :src="item.icon" />
              <p class="line-hei24">{{ item.title }}</p>
            </div>
            <template>
              <el-popconfirm title="确定删除吗?" @confirm="del(item.id)">
                <el-button slot="reference" type="danger">删除</el-button>
              </el-popconfirm>
            </template>
          </el-col>
        </el-row>
        <div class="clear h20"></div>
      </div>

    </div>
    <div class="clear h30"></div>
    <footer-pub></footer-pub>

    <el-dialog title="添加" :visible.sync="dialogTableVisible">
      <el-row>
        <el-col :span="10">
          <el-input v-model="appName" placeholder="请输入名称"></el-input>
        </el-col>

        <el-col :span="10">
          <el-select v-model="appType" placeholder="请选择类型" style="width: 90%">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="loadData">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <el-table :data="tableData">
        <el-table-column type="index"></el-table-column>
        <el-table-column property="name" label="名称"></el-table-column>
        <el-table-column property="leix_dictText" label="类型"></el-table-column>
        <el-table-column label="图标">
          <template slot-scope="scope">
            <el-image class="img-hei" style="width: 64px height: 64px" :src="scope.row.icon"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link type="success" @click="addApp(scope.row)">添加</el-link>
            <el-divider direction="vertical"></el-divider>
            <a :href="scope.row.url" target="_blank" class="buttonText">
              <font color="#0000FF">查看</font>
            </a>
          </template>
        </el-table-column>
      </el-table>
      <p>&nbsp</p>

      <el-pagination background layout="prev, pager, next,sizes" :total="total" :page-sizes="[20, 50, 100]"
        :page-size="20" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogTableVisible = false">确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _axios from "../httpService"
import headerPub from "../components/headerPub"
import footerPub from "../components/footerPub"

export default {
  components: {
    headerPub,
    footerPub,
  },
  data() {
    return {
      options: [
        {
          value: "1",
          label: "子系统",
        },
        {
          value: "2",
          label: "快捷链接",
        },
      ],
      appName: null,
      appType: null,
      loading: false,
      tableData: [],
      dialogTableVisible: false,
      appList: [],
      shortcutList: [],
      total: 0,
      pageNo: 1,
      pageSize: 20,
    }
  },
  mounted() {
    let that = this

    this.loadData4AppList()
    this.loadData4ShortcutList()
    this.loadData()
  },

  methods: {
    loadData4AppList() {
      let that = this

      _axios
        .get("/menghUserApp/menghUserApp/list", {
          params: {
            leix: 1,
            pageSize: 99,
            column: "createTime",
            order: "desc"
          }
        })
        .then((resp) => {
          if (resp.data.success) {
            that.appList = []
            resp.data.result.records.forEach((element) => {
              let obj = {}
              obj.id = element.id
              obj.icon = element.icon
              obj.title = element.name
              obj.url = element.url
              that.appList.push(obj)
            })
          }
        })
    },

    loadData4ShortcutList() {
      let that = this

      _axios
        .get("/menghUserApp/menghUserApp/list", {
          params: {
            leix: 2,
            pageSize: 99,
            column: "createTime",
            order: "desc"
          }
        })
        .then((resp) => {
          if (resp.data.success) {
            that.shortcutList = []
            resp.data.result.records.forEach((element) => {
              let obj = {}
              obj.id = element.id
              obj.icon = element.icon
              obj.title = element.name
              obj.url = element.url
              that.shortcutList.push(obj)
            })
          }
        })
    },

    addApp(obj) {
      let that = this

      _axios
        .post("/menghUserApp/menghUserApp/add", {
          appid: obj.id,
          leix: obj.leix,
        })
        .then((resp) => {
          if (resp.data.success) {
            that.loadData4AppList()
            that.loadData4ShortcutList()

            that.$message({
              type: "success",
              message: resp.data.message,
            })
          } else {
            that.$message({
              type: "error",
              message: resp.data.message,
            })
          }
        })
    },

    reset() {
      this.appName = null
      this.appType = null

      this.loadData()
    },

    del(id) {
      let that = this

      _axios
        .delete("/menghUserApp/menghUserApp/delete", { params: { id: id } })
        .then((resp) => {
          if (resp.data.success) {
            that.$message({
              type: "info",
              message: resp.data.message,
            })

            that.loadData4AppList()
            that.loadData4ShortcutList()
          } else {
            that.$message({
              type: "error",
              message: resp.data.message,
            })
          }
        })
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.loadData()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.pageNo = val
      this.loadData()
    },

    loadData() {
      let that = this

      // app 快捷链接
      that.loading = true
      _axios
        .get("/roleApp/roleApp/getListByRoleIds", {
          params: {
            roleIds: localStorage.getItem("roleIds"),
            appName: that.appName,
            appType: that.appType
          },
        })
        .then((resp) => {
          that.loading = false
          if (resp.data.success) {
            that.total = resp.data.result.total
            that.tableData = resp.data.result.records
          }
        })
    },

    goBack() {
      this.$router.go(-1) //返回上一层
    },
    showDlg() {
      this.dialogTableVisible = true
    },
    jump(url) {
      window.open(url)
    },
  },
}
</script>
<style lang="less" scoped>
.img-hei img {
  height: 60px !important;
}

.sub_title {
  position: relative;
  margin: 0px 0px 30px 0px;

  &:before {
    position: absolute;
    content: '';
    width: 200px;
    bottom: -1px;
    border-bottom: 1px solid #ff881f;
  }

  h3 {
    float: left;
    margin: 0px;
    border-bottom: 4px solid #ff881f;
    padding: 5px 0px;
    font-size: 18px;
  }
}
</style>

