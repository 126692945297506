<template>
  <div>
    <header-pub></header-pub>
    
    <div class="clear h10"></div>
    <div class="clearfix width-1200">
      <el-page-header @back="goBack" content="待办事宜"></el-page-header>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="待办事项" name="1">
          <div class="card-hei300 frame-gray clearfix">
            <el-table
                :data="tableData"
                stripe
                v-loading="loading"
                element-loading-text="读取中..."
            >
              <el-table-column type="index"> </el-table-column>
              <el-table-column prop="createTime" label="创建时间">
              </el-table-column>
              <el-table-column prop="type_dictText" label="类型">
              </el-table-column>
              <el-table-column prop="name" label="任务名称"> </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <a :href="scope.row.lianj" target="_blank" class="buttonText"
                  ><font color="#FF0000">查看</font></a
                  >
                </template>
              </el-table-column>
            </el-table>
            <p>&nbsp;</p>
            <el-pagination
                background
                layout="prev, pager, next,sizes"
                :total="total"
                :page-sizes="[20, 50, 100]"
                :page-size="20"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已办事项" name="2">
          <el-table
              :data="tableData2"
              stripe
              v-loading="loading"
              element-loading-text="读取中..."
          >
            <el-table-column type="index"> </el-table-column>
            <el-table-column prop="createTime" label="创建时间">
            </el-table-column>
            <el-table-column prop="type_dictText" label="类型"> </el-table-column>
            <el-table-column prop="name" label="任务名称"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <a :href="scope.row.lianj" target="_blank" class="buttonText"
                ><font color="#0000FF">查看</font></a
                >
              </template>
            </el-table-column>
          </el-table>
          <p>&nbsp;</p>
          <el-pagination
              background
              layout="prev, pager, next,sizes"
              :total="total2"
              :page-sizes="[20, 50, 100]"
              :page-size="20"
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="clear h10"></div>
    <div class="clear h10"></div>
    <div class="clear h10"></div>

    <footer-pub></footer-pub>
  </div>
</template>

<script>
import _axios from "../httpService";
import headerPub from "../components/headerPub";
import footerPub from "../components/footerPub";

export default {
  components: {
    headerPub,
    footerPub,
  },
  data() {
    return {
      loading: false,
      activeName: "1",
      tableData: [],
      tableData2: [],
      total: 0,
      total2: 0,
      pageNo: 1,
      pageSize: 20,
      pageNo2: 1,
      pageSize2: 20,
    };
  },

  mounted() {
    // 待办
    this.loadData(0);
  },

  methods: {
    goBack() {
      this.$router.go(-1) //返回上一层
    },

    loadData(type) {
      let that = this;

      that.loading = true;
      if (type == 0) {
        _axios
          .get("/daiblb/daiblb/list", {
            params: {
              type: type,
              pageNo: this.pageNo,
              pageSize: this.pageSize,
              column: "createTime",
              order: "desc",
            },
          })
          .then((resp) => {
            that.loading = false;
            if (resp.data.success) {
              that.tableData = resp.data.result.records;
              that.total = resp.data.result.total;
            }
          });
      }

      if (type == 1) {
        _axios
          .get("/daiblb/daiblb/list", {
            params: {
              type: type,
              pageNo: this.pageNo2,
              pageSize: this.pageSize2,
              column: "createTime",
              order: "desc",
            },
          })
          .then((resp) => {
            that.loading = false;
            if (resp.data.success) {
              that.tableData2 = resp.data.result.records;
              that.total2 = resp.data.result.total;
            }
          });
      }
    },

    handleClick(tab) {
      console.log(tab.name);
      if (tab.name == 1) this.loadData(0);

      if (tab.name == 2) this.loadData(1);
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.loadData(0);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val;
      this.loadData(0);
    },

    handleSizeChange2(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize2 = val;
      this.loadData(1);
    },
    handleCurrentChange2(val) {
      console.log(`当前页: ${val}`);
      this.pageNo2 = val;
      this.loadData(1);
    },
  },
};
</script>

<style>
</style>