import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Router from 'vue-router';
import router from "./router";
import vuels from 'vue-ls'


import Keycloak from 'keycloak-js'
import _axios from "./httpService";

let initOptions = {
  url: 'https://yjb.zjic.com.cn/auth/', realm: 'zhongjia', clientId: 'vue-demo', onLoad: 'login-required'
}

let keycloak = Keycloak(initOptions);

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Router);

// 为了使用Vue.ls
Vue.use(vuels, {
  namespace: 'pro__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
})


keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    keycloak.logout()
    
  } else {
    Vue.prototype.$keycloak = keycloak
    Vue.ls.set("Authorization", keycloak.token, 7 * 24 * 60 * 60 * 1000)
    _axios.get("/sys/cas/client/validateLogin", {}).then(response => {
      console.log("----cas 登录--------", response);
      if (response.data.success) {
        window.localStorage.setItem('userInfo', JSON.stringify(response.data.result.userInfo));

        new Vue({
          router,
          render: h => h(App)
        }).$mount("#app");

      } else {

      }

    }).catch(error => {
    })
  }
})

//Token Refresh
setInterval(() => {
  keycloak.updateToken(70).then((refreshed) => {
    if (refreshed) {
    } else {

    }
  }).catch(() => {

  });
}, 6000)
