<template>
  <div class="clearfix">
    <div class="block top-h pos-rel">
      <div class="clearfix logo-bg pos-rel">
        <div class="width-1200 pos-rel r-bg">
          <img @click="jump('index')" src="../assets/img/logo_top.png" />
          <ul class="fxr r-li">
            <!-- <li @click="jump('index')">返回首页</li> -->
            <li>姓名: {{ userInfo.realName }}</li>
<!--            <li>部门: {{ userInfo.departName }}</li>-->
            <template v-if="yewfw == 3">
              <li>
                本月财险目标:{{ yiejzb.caicx_completed }} /
                {{ yiejzb.caicx_targetAmount }}
              </li>
              <li>
                本月寿险目标: {{ yiejzb.shoux_completed }} /
                {{ yiejzb.shoux_targetAmount }}
              </li>
            </template>
<!--            <li v-if="yewfw == 1">本月财险目标: 0/0</li>-->
<!--            <li v-if="yewfw == 2">本月寿险目标: 0/0</li>-->
            <li @click="dialogVisible = true">【修改密码</li>
            <li @click="logout">退出系统】</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      v-loading="loading"
      element-loading-text="请求中..."
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <span>
        <el-row>
          <el-col :span="4">新密码</el-col>
          <el-col :span="20"><el-input type="password" v-model="password"></el-input></el-col>
        </el-row>
        <el-row>
          <el-col :span="4">确认密码</el-col>
          <el-col :span="20"><el-input type="password" v-model="password2"></el-input></el-col>
        </el-row>
      </span>

      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="xiugyhxx">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import _axios from "../httpService";

export default {
  name: "index",
  components: {},
  data() {
    return {
      loading: false,
      password: null,
      password2: null,
      dialogVisible: false,
      yiejzb: {},
      yewfw: null,
      userInfo: {
        departName: "",
        roleName: "",
        userName: "",
      },
    };
  },
  created() {
    Vue.ls.set("token", this.$keycloak.token);
  },
  mounted() {
    let that = this;
    _axios.get("/sys/user/queryByLonginName", {}).then((resp) => {
      if (resp.data.success && resp.data.result != null)
        that.userInfo = resp.data.result;
      localStorage.setItem("orgCode2", resp.data.result.orgCode);
      localStorage.setItem("roleIds", resp.data.result.roleIds);
    });

    let tmp = localStorage.getItem("userInfo");
    if (tmp != null) {
      this.yewfw = JSON.parse(tmp).yewfw;
    }

    // 业绩指标
    _axios
      .get("/sys/user/getShouxCaicxYiej", {
        params: {},
      })
      .then((resp) => {
        that.yiejzb = resp.data.result;
      });
  },

  methods: {
    jump(url) {
      // window.open(url, "_self");
      this.$router.go(-1);
    },
    xiugyhxx() {
      // window.open(
      //   "https://yjb.zjic.com.cn/auth/realms/zhongjia/protocol/openid-connect/auth?client_id=account-console&redirect_uri=https%3A%2F%2Fyjb.zjic.com.cn%2Fauth%2Frealms%2Fzhongjia%2Faccount%2F%23%2Fsecurity%2Fsigningin&state=f69ad158-e77a-4951-84b9-dbe96141352d&response_mode=fragment&response_type=code&scope=openid&nonce=6df6e5d4-4504-4bd4-80b9-fd0857c4aac8&kc_action=UPDATE_PASSWORD&code_challenge=chXRcFF3EaTNqxCzPWUsE-FHp13YlBTzvC1GbkmL_Js&code_challenge_method=S256"
      // );
      let that = this;

      if (this.password != this.password2) {
        this.$message({
          message: "两次输入的密码不一样！",
          type: "error",
        });
        return;
      }

      this.loading = true;
      _axios
        .get("/sys/user/updateKeyCloakPassWord", {
          params: {
            userId: that.userInfo.id,
            newPassword: this.password,
          },
        })
        .then((resp) => {
          that.loading = false;
          if (resp.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "修改失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          that.loading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    logout() {
      Vue.prototype.$keycloak.logout();
    },
  },
};
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 10px;
}

.card-hei300 {
  height: 300px;
}
.card-hei320 {
  height: 312px;
}
.card-hei {
  height: 200px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}
.imgBg {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
}
.header {
  width: 80px;
  height: 80px;
  border: 1px solid #ddd;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  overflow: hidden;
}
.header img {
  height: 100%;
  width: 100%;
}
.logo-bg {
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;
  background:url(../assets/img/top_bg.jpg) no-repeat center center;
  background-repeat: repeat-x;
  &:before{
    position: absolute;
    content: '';
    background:url(../assets/img/top_right.png) no-repeat right center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    right: 0px;
  }
  .r-bg{
    padding: 10px 0px;

  }
}
.logo-bg img {
  height: 45px;
}
.r-li li {
  float: left;
  margin-left: 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 50px;
  cursor: pointer;
}
</style>
