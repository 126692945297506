<template>
  <div class="clearfix">
    <header-pub></header-pub>

    <div class="clearfix width-1200">
      <div class="clear h10"></div>
      <el-page-header @back="goBack" content="通知公告"></el-page-header>
      <div class="clear h10"></div>
      <div class="main-con clearfix">
        <ul class="list-ul notice">
          <li
            class="clearfix pos-rel"
            v-for="(item, index) in noticeData"
            :key="index"
          >
            <img class="fxl"
              src="https://www.bjyamyy.cn/img/new.6ebfd6dc.png"
              v-if="item.shifzd"
              width="31"
            />
            <router-link class="fxl text-wid" style="width:90%;"
              :to="{ path: '/tongzgg_detail', query: { id: item.id } }"
              >{{ item.title }}</router-link
            >
            <span class="pos-abs data">{{ item.date }}</span>
          </li>
        </ul>
        <div class="clear h10"></div>
        <center>
          <el-pagination
            background
            layout="prev, pager, next,sizes"
            :total="total"
            :page-sizes="[20, 50, 100]"
            :page-size="20"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </center>
      </div>
    </div>

    <div class="clear h10"></div>
    <div class="clear h10"></div>
    <div class="clear h10"></div>
    
    <footer-pub></footer-pub>
  </div>
</template>

<script>
import _axios from "../httpService";
import headerPub from "../components/headerPub";
import footerPub from "../components/footerPub";

export default {
  components: {
    headerPub,
    footerPub,
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      noticeData: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let that = this;

      // 通知公告
      that.loading = true;
      _axios
        .get("/sys/message/sysMessage/list", {
          params: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
          },
        })
        .then((resp) => {
          that.loading = false;
          if (resp.data.success) {
            that.total = resp.data.result.total;
            that.noticeData = [];

            resp.data.result.records.forEach((element) => {
              let obj = element;
              obj.title = element.esTitle;
              obj.date = element.esSendTime;
              that.noticeData.push(obj);
            });
          }
        });
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.loadData(0);
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val;
      this.loadData(0);
    },

    goBack() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>
<style scoped>
.list-ul {
  width: 100%;
}
.list-ul li {
  display: block;
  padding: 15px 60px 10px 0px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}
.list-ul li .data {
  right: 0px;
  top: 20px;
}
.list-ul li a {
  width: 100%;
  display: block;
}
</style>

