<template>
  <div class="clearfix">
    <header-pub></header-pub>
    <div class="clear h10"></div>
    <div class="clearfix width-1200">
      <el-page-header @back="goBack" content="详情"></el-page-header>
      <div class="clear h10"></div>
      <div class="clearfix main-con">
        <div class="title">{{ notice.esTitle }}</div>
        <div class="time">{{ notice.esSendTime }}</div>
        <div class="content" v-html="notice.esContent"></div>
      </div>
    </div>

    <div class="clear h10"></div>
    <div class="clear h10"></div>
    <div class="clear h10"></div>
    
    <footer-pub></footer-pub>
  </div>
</template>

<script>
import _axios from "../httpService";
import headerPub from "../components/headerPub";
import footerPub from "../components/footerPub";
export default {
  components: {
    headerPub,
    footerPub,
  },
  data() {
    return {
      notice: {},
    };
  },
  mounted() {
    let that = this;

    _axios
      .get("/sys/message/sysMessage/queryById", {
        params: { id: this.$route.query.id },
      })
      .then((resp) => {
        if (resp.data.success) {
          that.notice = resp.data.result;
        }
      });
  },
  methods: {
    goBack() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>
<style scoped>
.main-con .title {
  display: block;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 20px 20px 10px 10px;
}
.main-con .time {
  display: block;
  font-size: 14px;
  text-align: center;
  color: #999;
}
.main-con .content {
  display: block;
  line-height: 28px;
  text-indent: 2em;
  font-size: 14px;
}
</style>