<template>
  <div class="clearfix tab-only">


    <header-pub></header-pub>
    <el-carousel height="460px" trigger="click">
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <div :style="{ 'background-image': 'url(' + item.img + ')' }" class="imgBg"></div>
      </el-carousel-item>
    </el-carousel>
    <div class="clear"></div>
    <div class="width-1200 block-one pos-rel clearfix">
      <el-row class="marginNo fxr paddingNo" style="width:74%">
        <el-col :span="12">
          <el-card class="box-card my-card pad-out bg-gray">
            <div slot="header" class="clearfix pos-rel">
              <span class="title">通知公告</span>
              <router-link to="/tongzgg_more">
                <el-button class="fxr padNo line-hei20" type="text">更多</el-button>
              </router-link>
            </div>
            <div class="card-hei320 clearfix" style="height:221px;overflow:hidden;">
              <ul class="notice clearfix">
                <li class="text-wid pos-rel pointer" v-for="(item, index) in noticeData" :key="index">
                  <img src="https://www.bjyamyy.cn/img/new.6ebfd6dc.png" v-if="item.shifzd" width="31" />
                  <router-link :to="{ path: '/tongzgg_detail', query: { id: item.id } }">{{ item.title | ellipsis
                  }}</router-link>
                  <span class="pos-abs data">{{ item.date }}</span>
                </li>
              </ul>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card my-card pad-out margin5">
            <div slot="header" class="clearfix">
              <span class="title">业绩排行榜</span>
            </div>
            <div class="card-hei300 pos-rel clearfix" style="height:100% !important;">
              <el-row class="marginNo paddingNo">
                <el-col :span="12">
                  <div class="clearfix sub-title"><span>寿险</span></div>
                  <el-table :data="yiejphb.slice(0, 3)" style="width: 100%">
                    <el-table-column show-overflow-tooltip prop="bumxm" label="部门">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="completed" label="业绩" width="80">
                    </el-table-column>
                  </el-table>
                </el-col>
                <el-col :span="12">
                  <div class="clearfix sub-title"><span>财险</span></div>
                  <el-table :data="caicxyjphb.slice(0, 3)" style="width: 100%">
                    <el-table-column show-overflow-tooltip prop="deptName" label="部门">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="permium" label="业绩" width="100">
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="bg-one clearfix">
      <div class="width-1200 clearfix">
        <el-row class="marginNo paddingNo">
          <el-col :span="12">
            <el-card class="box-card my-card pad-out" style="padding-right:20px;">
              <div slot="header" class="clearfix pos-rel">
                <span class="title">应用系统</span>
                <router-link to="/app_more">
                  <el-button class="fxr padNo line-hei20" type="text">更多</el-button>
                </router-link>
              </div>
              <div class="card-hei320 clearfix gray-block">
                <el-row class="paddingNo marginNo">
                  <el-col class="text-center" v-for="(item, index) in appList.slice(0, 4)" :span="6" :key="index">
                    <div class="block-list" @click="jump(item.url, item.linkType)">
                      <img :src="item.icon" />
                      <el-tooltip v-if="item.title.length > 5" :content="item.title" effect="light">
                        <p class="line-hei20 row-two">{{ item.title }}</p>
                      </el-tooltip>
                      <p v-else class="line-hei20 row-two">{{ item.title }}</p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="box-card my-card pad-out" style="padding-left:20px;">
              <div slot="header" class="clearfix pos-rel">
                <span class="title">快捷链接</span>
                <router-link to="/app_more">
                  <el-button class="fxr padNo line-hei20" type="text">更多</el-button>
                </router-link>
              </div>
              <div class="card-hei320 clearfix gray-block">
                <el-row class="paddingNo marginNo">
                  <el-col class="text-center" v-for="(item, index) in kuaijljList.slice(0, 4)" :span="6" :key="index">
                    <div class="block-list" @click="jump(item.url, item.linkType)">
                      <img :src="item.icon" />
                      <el-tooltip v-if="item.title.length > 5" :content="item.title" effect="light">
                        <p class="line-hei20 row-two">{{ item.title }}</p>
                      </el-tooltip>
                      <p v-else class="line-hei20 row-two">{{ item.title }}</p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="width-1200 clearfix">
      <el-row class="marginNo" style="padding:0px 50px;">
        <el-col :span="24">
          <el-card class="box-card my-card pos-rel pad-out">
            <router-link to="/daib_more">
              <el-button class="fxr more-btn" type="text">更多</el-button>
            </router-link>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="待办事项" name="1">
                <div class="card-hei300 table-bg clearfix">
                  <el-table class="paddingNoLR" :data="tableData" stripe height="300px" style="width: 100%">
                    <el-table-column type="index"> </el-table-column>
                    <el-table-column show-overflow-tooltip prop="createTime" label="创建时间">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="type_dictText" label="类型">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="name" label="任务名称">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip label="操作">
                      <template slot-scope="scope">
                        <a :href="scope.row.lianj" target="_blank" class="buttonText">
                          <font color="#0000FF">查看</font>
                        </a>
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="50"
                  >
                  </el-pagination> -->
                </div>
              </el-tab-pane>
              <el-tab-pane label="已办事项" name="2">
                <div class="table-bg clearfpx">
                  <el-table class="paddingNoLR" :data="tableData2" stripe height="300px" style="width: 100%">
                    <el-table-column type="index"> </el-table-column>
                    <el-table-column prop="createTime" label="创建时间">
                    </el-table-column>
                    <el-table-column prop="type_dictText" label="类型">
                    </el-table-column>
                    <el-table-column prop="name" label="任务名称">
                    </el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <a :href="scope.row.lianj" target="_blank" class="buttonText">
                          <font color="#0000FF">查看</font>
                        </a>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <!-- <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="50"
                > -->
                <!-- </el-pagination> -->
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
    </div>
    
    <footer-pub></footer-pub>
  </div>
</template>

<script>
import Vue from 'vue'
import _axios from '../httpService'
import headerPub from '../components/headerPub'
import footerPub from '../components/footerPub'
import { Console } from 'console'
export default {
  name: 'index',
  components: {
    headerPub,
    footerPub,
  },
  data() {
    return {
      screenWidth: window.screen.width,
      screenHeight: window.innerHeight,

      yiejphb: [],
      caicxyjphb: [],
      userInfo: {
        departName: '',
        roleName: '',
        userName: '',
      },
      kuaijljList: [],
      imgList: [],
      activeName: '1',
      tableData: [],
      tableData2: [],
      appList: [],
      noticeData: [],
    }
  },
  created() {
    Vue.ls.set('token', this.$keycloak.token)
  },
  mounted() {
    let that = this
    console.log("屏幕宽度: " + that.screenWidth)

    // 轮播图
    // _axios
    //   .get('/sys/dictItem/list', { params: { dictId: '1415199178588008449', status: 1} })
    //   .then((resp) => {
    //     if (resp.data.success) {
    //       resp.data.result.records.forEach((element) => {
    //         let obj = {}
    //         obj.img = element.itemValue
    //         that.imgList.push(obj)
    //       })
    //     }
    //   })
    _axios
      .get('/lunbt/lunbt/list', { params: { column: 'xianssx', order: 'desc', fenbl: this.screenWidth, shifqy: 1 } })
      .then((resp) => {
        if (resp.data.success) {
          resp.data.result.records.forEach((element) => {
            let obj = {}
            obj.img = element.tup
            that.imgList.push(obj)
          })
        }
      })

    // 通知公告
    _axios
      .get('/sys/message/sysMessage/list', {
        params: { pageNo: 1, pageSize: 7 },
      })
      .then((resp) => {
        if (resp.data.success) {
          resp.data.result.records.forEach((element) => {
            let obj = {}
            obj = element
            obj.title = element.esTitle
            obj.date = element.esSendTime
            that.noticeData.push(obj)
          })
        }
      })

    // 应用系统
    _axios
      .get('/menghUserApp/menghUserApp/list', {
        params: { leix: 1, pageSize: 99, column: 'createTime', order: 'desc' },
      })
      .then((resp) => {
        if (resp.data.success) {
          resp.data.result.records.forEach((element, index) => {
            let obj = {}
            obj.icon = element.icon
            obj.title = element.name
            obj.url = element.url
            obj.linkType = element.linkType

            if (index < 4) that.appList.push(obj)
          })
        }
      })

    // 快捷连接
    _axios
      .get('/menghUserApp/menghUserApp/list', {
        params: { leix: 2, pageSize: 99, column: 'createTime', order: 'desc' },
      })
      .then((resp) => {
        if (resp.data.success) {
          resp.data.result.records.forEach((element, index) => {
            let obj = {}
            obj.icon = element.icon
            obj.title = element.name
            obj.url = element.url
            obj.linkType = element.linkType

            if (index < 8) that.kuaijljList.push(obj)
          })
        }
      })

    // 待办
    _axios
      .get('/daiblb/daiblb/list', {
        params: { type: 0, column: 'createTime', order: 'desc' },
      })
      .then((resp) => {
        if (resp.data.success) that.tableData = resp.data.result.records
      })

    // 已办
    _axios
      .get('/daiblb/daiblb/list', {
        params: { type: 1, column: 'createTime', order: 'desc' },
      })
      .then((resp) => {
        if (resp.data.success) that.tableData2 = resp.data.result.records
      })

    // 寿险业绩排行榜
    _axios
      .get('/sys/user/getYejphb', {
        params: {},
      })
      .then((resp) => {
        if (resp.data.result != null && resp.data.result.length > 0) {
          resp.data.result.forEach((element) => {
            element.bumxm = element.orgName + ' ' + element.brokerName
          })

          that.yiejphb = resp.data.result
        }
      })

    // 财产险业绩排行榜
    _axios
      .get('/sys/user/getCaicxYejphb', {
        params: {},
      })
      .then((resp) => {
        if (resp.data.result != null && resp.data.result.length > 0) {
          resp.data.result.forEach((element) => {
          })

          that.caicxyjphb = resp.data.result
        }
      })
  },

  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 15) {
        return value.slice(0, 15) + '...'
      }
      return value
    },
  },

  methods: {
    jump(url, type) {
      let orgCode2 = localStorage.getItem('orgCode2')
      if (type == 'baob') window.open(url + '&isShow=1&orgCode=' + orgCode2)
      else window.open(url)
    },

    handleClick(tab, event) {
      console.log(tab, event)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-table td {
  padding: 10px !important;
}

.card-hei300 {
  height: 300px;
}

.card-hei320 {
  height: 312px;
}

.card-hei {
  height: 225px;
  overflow: auto;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.imgBg {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
}

.header {
  top: 10px;
  width: 80px;
  height: 80px;
  border: 1px solid #ddd;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  overflow: hidden;
}

.header img {
  height: 100%;
  width: 100%;
}

.icon-text {
  color: #fff;
  font-size: 30px;
  font-weight: normal;
}

.notice li {
  display: block;
  padding: 0px 70px 0px 15px;
  line-height: 28px;
  margin: 5px 20px;
}

.notice li:after {
  position: absolute;
  content: '';
  left: 3px;
  top: 9px;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-left: 5px solid #00ca72;
  border-bottom: 3px solid transparent;
}

.notice li:hover,
.notice li:hover .data {
  color: #409eff;
}

.notice .data {
  right: 0px;
  font-size: 12px;
  color: #999;
  padding: 2px 0px 0px 0px;
}

.user-font {
  padding: 20px 0px 0px 80px;
  height: 110px;
}

.user-font p {
  padding: 0px 0px 0px 40px;
}

.user-font span {
  left: 0px;
}

.logo-bg img {
  height: 45px;
}

.sub-title {
  text-align: center;
  font-weight: bold;
  padding: 10px 0px;
  display: block;
  border-bottom: 0px solid #ddd;
  font-size: 16px;
  color: #666;

  span {
    border-bottom: 1px solid #f6891b;
  }
}

/deep/.el-tabs__nav-wrap::after {
  background: #fff;
}

/deep/.el-table .cell.el-tooltip {
  padding: 0px;
}

/deep/.el-table.paddingNoLR td .cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/deep/.el-tabs__header {
  margin-bottom: 12px;
}

.block-one {
  height: 500px;

  &:before {
    position: absolute;
    content: '';
    background: url(../assets/img/img_01.jpg) no-repeat left center;
    background-size: 100% auto;
    height: 100%;
    left: 0px;
    width: 400px;
  }
}

.table-bg {
  background: url(../assets/img/img_03.jpg) no-repeat left top !important;
  background-size: 100% auto !important;
  padding: 30px;
}

.bg-one {
  background: url(../assets/img/img_02.jpg) no-repeat left center !important;
  background-size: 100% auto !important;
}

/deep/.el-table,
.el-table__expanded-cell {
  background: transparent;
}

.pad-out {
  padding: 60px 50px;
  height: 300px;
  border: 0px;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #fff !important;

  &.bg-gray {
    background: #f4f4f4 !important;
  }
}

/deep/.el-table td,
.el-table th.is-leaf {
  border: 0px !important;
}

/deep/.table-bg .el-table th {
  background: transparent !important;
  border-bottom: 0px dashed #bebdbd;
  color: #000;
}

/deep/.table-bg .el-table tr {
  background: transparent !important;
  border-bottom: 1px dashed #bebdbd;
}

/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #bebdbd !important;
}

.gray-block {
  height: 110px;
  padding: 50px 10px;
  background: #f4f4f4;
}

/deep/.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background: transparent !important;
}

/deep/.el-card {
  background: transparent !important;
}

@media (max-width: 1300px) {
  .width-1200 {
    width: 99%;
  }

  .pad-out {
    padding: 30px 10px;
  }
}

/deep/.el-table--scrollable-x .el-table__body-wrapper {
  overflow: hidden !important;
}
</style>
